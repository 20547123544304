import * as lazysizes from 'lazysizes';
import 'lazysizes/plugins/respimg/ls.respimg';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/object-fit/ls.object-fit';

import { Cursor } from './Cursor.js';
import { Images } from './Images.js';
import { Video } from './Video.js';

new Cursor('(hover:hover) and (pointer:fine)');
new Images();
new Video();
