import M from '@superstructure.net/m';
import C from '@superstructure.net/c';

/**
 * Video
 */
class Video extends M {
    constructor(mediaQuery) {
        super(mediaQuery);
    }

    bindEvents() {
        this.onVideoEnded = this.onVideoEnded.bind(this);

        this.wrapper.addEventListener('click', this.onVideoEnded);
        this.video.addEventListener('ended', this.onVideoEnded);
    }

    onInit() {
        console.log('Video.onInit()');

        this.events = new C();

        this.video = document.querySelector('[data-Video-role~="video"]');
        this.wrapper = document.querySelector('[data-Video-role~="wrapper"]');
        if (!this.video || !this.wrapper) return;

        this.bindEvents();
    }

    onVideoEnded() {
        console.log('Video.onVideoEnded()');

        this.wrapper.setAttribute('data-Video-isEnded', true);
    }

    onResize(viewport, isUIResize) {
        console.log('Video.onResize()');
    }

    onDestroy() {
        console.log('Video.onDestroy()');

        this.wrapper.removeEventListener('click', this.onVideoEnded);
        this.video.removeEventListener('ended', this.onVideoEnded);
    }
}

export { Video };
