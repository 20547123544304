import M from '@superstructure.net/m';
import C from '@superstructure.net/c';
import { S } from '@superstructure.net/s';
import { lerp } from '@superstructure.net/utils';

/**
 * Cursor
 */
const TOTAL_STEPS = 12;
const INTERVAL = 500;

const state = new S({ ignore: false });

class Cursor extends M {
    constructor(mediaQuery) {
        super(mediaQuery);
    }

    build() {
        this.cursor = document.createElement('span');
        this.cursor.setAttribute('data-Cursor-role', 'cursor');

        document.body.append(this.cursor);
    }

    unbuild() {
        if (this.cursor) this.cursor.remove();
    }

    bindEvents() {
        this.onFrame();

        this.interval = setInterval(() => {
            this.onInterval();
        }, INTERVAL);

        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseEnterIgnore = this.onMouseEnterIgnore.bind(this);
        this.onMouseLeaveIgnore = this.onMouseLeaveIgnore.bind(this);

        this.events.on('mousemove', null, this.onMouseMove);
        this.events.on('mouseenter', 'header', this.onMouseEnterIgnore, { capture: true });
        this.events.on('mouseleave', '[data-Cursor-role~="ignore"]', this.onMouseLeaveIgnore, { capture: true });

        state.on('ignore', () => {
            document.documentElement.setAttribute('data-Cursor-isActive', !state.get('ignore'));
        });
    }

    onInit() {
        console.log('Cursor.onInit()');

        this.events = new C();
        this.frame = null;
        this.interval = null;

        this.currentStep = 0;

        this.mousePosition = {
            x: 0,
            y: 0,
            interpolatedX: 0,
            interpolatedY: 0,
        };

        document.documentElement.setAttribute('data-Cursor-isActive', true);

        this.build();
        this.bindEvents();
    }

    onMouseMove(event) {
        this.mousePosition.x = event.clientX;
        this.mousePosition.y = event.clientY;
    }

    onMouseEnterIgnore(event) {
        console.log('Cursor.onMouseEnterIgnore()', event.target);

        state.set('ignore', true);
    }
    onMouseLeaveIgnore(event) {
        console.log('Cursor.onMouseLeaveIgnore()', event.target, event.target.closest('[data-Cursor-role~="ignore"]'));

        state.set('ignore', false);
    }

    onFrame() {
        this.mousePosition.interpolatedX = lerp(this.mousePosition.interpolatedX, this.mousePosition.x, 0.1);
        this.mousePosition.interpolatedY = lerp(this.mousePosition.interpolatedY, this.mousePosition.y, 0.1);

        if (this.cursor) {
            this.cursor.style.transform = `translate(${this.mousePosition.interpolatedX}px,${this.mousePosition.interpolatedY}px)`;
        }

        this.frame = requestAnimationFrame(() => {
            this.onFrame();
        });
    }

    onInterval() {
        if (!this.cursor) return;

        this.currentStep = this.currentStep < TOTAL_STEPS - 1 ? this.currentStep + 1 : 0;

        this.cursor.style.setProperty('--step', this.currentStep);
    }

    onResize(viewport, isUIResize) {
        console.log('Cursor.onResize()');
    }

    onDestroy() {
        console.log('Cursor.onDestroy()');

        this.events.off();
        cancelAnimationFrame(this.frame);
        clearInterval(this.interval);

        this.unbuild();
    }
}

export { Cursor, state };
