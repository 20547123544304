import M from '@superstructure.net/m';
import C from '@superstructure.net/c';

/**
 * Images
 */
const TOTAL_IMAGES = 3;
const INTERVAL = 4000;

class Images extends M {
    constructor(mediaQuery) {
        super(mediaQuery);
    }

    bindEvents() {
        this.interval = setInterval(() => {
            this.onInterval();
        }, INTERVAL);
    }

    onInit() {
        console.log('Cursor.onInit()');

        this.events = new C();
        this.interval = null;
        this.currentStep = -1;

        this.bindEvents();

        this.onInterval();
    }

    onInterval() {
        this.currentStep = this.currentStep < TOTAL_IMAGES - 1 ? this.currentStep + 1 : 0;

        const images = document.querySelectorAll('[data-Images-role~="image"]');
        if (!images) return;

        images.forEach((image, i) =>
            image.setAttribute('data-Images-isVisible', i === this.currentStep ? true : false),
        );
    }

    onResize(viewport, isUIResize) {
        console.log('Cursor.onResize()');
    }

    onDestroy() {
        console.log('Cursor.onDestroy()');

        this.events.off();

        clearInterval(this.interval);
    }
}

export { Images };
